import PropTypes from "prop-types";
import React from "react";
import { useLocation } from "react-router-dom";
import styled, { css } from "styled-components";

import { handleCustomerStatus, handleProductName } from "_helpers";

import Icon from "components/atomic/atoms/Icon";

const StyledContainer = styled.div`
  font-size: 1rem;
  font-weight: 700;
  padding-bottom: 20px;

  ${({ theme }) => css`
    color: ${theme.colors["cl-text-dark"]};
  `}

  @media (max-width: 991px) {
    ${({ hide }) =>
      hide &&
      css`
        display: none;
      `}
    padding-bottom: 0;
  }
`;

const StyledTitleContainer = styled.div`
  @media (max-width: 991px) {
    display: none;
  }
`;

const StyledTitle = styled.div`
  line-height: 3.125rem;
  font-size: 1.875rem;
  font-weight: 700;

  ${({ theme }) => css`
    color: ${theme.colors["cl-text-dark"]};
  `}
`;

const StyledSubtitle = styled.div`
  font-size: 1.125rem;
  font-weight: 500;

  ${({ theme }) => css`
    color: ${theme.colors["cl-text-dark"]};
  `}
`;

const StyledStatusContainer = styled.div`
  display: none;

  ${({ show }) =>
    show &&
    css`
      display: flex;
    `}

  align-items: center;
  padding-top: 16px;

  @media (max-width: 991px) {
    flex-direction: column;
    align-items: flex-start;
    padding-top: 0;
    padding-bottom: 12px;
  }
`;

const StyledStatusLabel = styled.div`
  @media (max-width: 991px) {
    margin-bottom: 8px;
  }
`;

const StyledStatusValue = styled.div`
  display: flex;
  align-items: center;
`;

const StyledStatusIconContainer = styled.div`
  margin-left: 8px;

  @media (max-width: 991px) {
    margin-left: 0;
  }
`;

const StyledStatusName = styled.div`
  margin-left: 8px;

  ${({ color }) =>
    color &&
    css`
      color: ${color};
    `}
`;

const CompanyTitle = ({ title, type, statusColor, status }) => {
  const location = useLocation();
  const isTrademark = location?.pathname?.includes("trademark");

  return (
    <StyledContainer hide={!isTrademark}>
      <StyledTitleContainer>
        <StyledTitle>{title}</StyledTitle>
        <StyledSubtitle>Business Type: {handleProductName(type)}</StyledSubtitle>
      </StyledTitleContainer>

      <StyledStatusContainer show={isTrademark}>
        <StyledStatusLabel>Trademark Status:</StyledStatusLabel>
        <StyledStatusValue>
          <StyledStatusIconContainer>
            <Icon icon="profile:status" color={statusColor} padding="6px" size="40px" rounded />
          </StyledStatusIconContainer>
          <StyledStatusName color={statusColor}>{handleCustomerStatus(status)}</StyledStatusName>
        </StyledStatusValue>
      </StyledStatusContainer>
    </StyledContainer>
  );
};

CompanyTitle.propTypes = {
  title: PropTypes.string,
  type: PropTypes.string,
  statusColor: PropTypes.string,
  status: PropTypes.string,
};

export default CompanyTitle;
