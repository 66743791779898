import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { error as errorNotification, success as successNotification } from "react-notification-system-redux";

import { PRODUCT_CODES } from "_constants/products";
import { handleError, handleResponse } from "_helpers/api";
import { CustomerService, OrdersService } from "_services";

export const getPaymentMethods = createAsyncThunk(
  "billing/getPaymentMethods",
  async function ({ customerId }, { dispatch, rejectWithValue }) {
    try {
      const response = await CustomerService.getPaymentMethods(customerId);
      return handleResponse(response);
    } catch (error) {
      const processedError = handleError(error);
      dispatch(errorNotification({ title: "Error", message: processedError?.message }));
      return rejectWithValue(processedError);
    }
  }
);

export const getSubscriptions = createAsyncThunk(
  "billing/getSubscriptions",
  async function ({ customerId }, { dispatch, rejectWithValue }) {
    try {
      const response = await CustomerService.getSubscriptions(customerId);
      return handleResponse(response);
    } catch (error) {
      const processedError = handleError(error);
      dispatch(errorNotification({ title: "Error", message: processedError?.message }));
      return rejectWithValue(processedError);
    }
  }
);

export const getSubscriptionsDetails = createAsyncThunk(
  "billing/getSubscriptionsDetails",
  async function (orders = [], { dispatch, fulfillWithValue, rejectWithValue }) {
    try {
      const subscriptionProducts = [];

      orders.forEach((order) => {
        order?.products?.forEach((product) => {
          if (product?.code?.subscription) {
            subscriptionProducts.push({ ...product, crmOrderId: order?.crmOrderId });
          }
        });
      });

      const requests = subscriptionProducts.map((product) =>
        OrdersService.getSubscriptionDetails({ orderId: product?.orderId, productId: product?.id })
      );

      const result = await Promise.all(requests)
        .then((responses) => {
          const payload = responses.map(({ data }, idx) => ({ ...data, product: subscriptionProducts[idx] }));

          return fulfillWithValue(payload);
        })
        .catch((error) => {
          const processedError = handleError(error);
          dispatch(errorNotification({ title: "Error", message: processedError?.message }));
          return rejectWithValue(processedError);
        });

      return result;
    } catch (error) {
      const processedError = handleError(error);
      dispatch(errorNotification({ title: "Error", message: processedError?.message }));
      return rejectWithValue(processedError);
    }
  }
);

export const createPaymentMethod = createAsyncThunk(
  "billing/createPaymentMethod",
  async function ({ customerId, cardDetails, cb }, { dispatch, rejectWithValue }) {
    try {
      await CustomerService.createPaymentMethod({ customerId, cardDetails });

      if (cb) cb();

      dispatch(
        successNotification({
          title: "Success",
          message: `Payment Method has been successfully updated`,
        })
      );

      dispatch(getPaymentMethods({ customerId }));
    } catch (error) {
      const processedError = handleError(error);
      dispatch(errorNotification({ title: "Error", message: processedError?.message }));
      return rejectWithValue(processedError);
    }
  }
);

export const createPaymentMethodWithRerunSubscription = createAsyncThunk(
  "billing/createPaymentMethodWithRerunSubscription",
  async function ({ product, customerId, cardDetails, cb }, { dispatch, rejectWithValue }) {
    try {
      const orderId = product?.orderId;
      const productId = product?.id;
      const productCode = product?.code?.code;
      const subscription = product?.code?.subscription;

      await CustomerService.createPaymentMethod({ customerId, cardDetails, updateProductStatus: "false" });

      dispatch(
        successNotification({
          title: "Success",
          message: `Payment Method has been updated successfully`,
          autoDismiss: 30,
        })
      );

      if (subscription) {
        await OrdersService.rerunSubscription({ orderId, productId });

        dispatch(
          successNotification({
            title: "Success",
            message: `Subscription has been re-run successfully`,
            autoDismiss: 30,
          })
        );
      }

      if (productCode === PRODUCT_CODES.incStatesAnnualReport) {
        await OrdersService.chargeAnnualComplianceReport({ orderId });

        dispatch(
          successNotification({
            title: "Success",
            message: `Annual Report has been charged successfully`,
            autoDismiss: 30,
          })
        );
      }

      if (cb) cb();

      dispatch(getPaymentMethods({ customerId }));
    } catch (error) {
      const processedError = handleError(error);
      dispatch(errorNotification({ title: "Error", message: processedError?.message }));
      return rejectWithValue(processedError);
    }
  }
);

export const cancelSubscription = createAsyncThunk(
  "billing/cancelSubscription",
  async function ({ orderId, productId, productName }, { getState, dispatch, rejectWithValue }) {
    try {
      await OrdersService.cancelSubscription(orderId, productId);
      dispatch(
        successNotification({
          title: "Unsubscribed",
          message: `Your ${productName} subscription has been successfully cancelled, you will not receive any further charges for it.`,
        })
      );
      const customerId = getState().user.details?.uid;
      dispatch(getSubscriptions({ customerId }));
    } catch (error) {
      const processedError = handleError(error);
      dispatch(errorNotification({ title: "Error", message: processedError?.message }));
      return rejectWithValue(processedError);
    }
  }
);

const billingSlice = createSlice({
  name: "billing",
  initialState: {
    paymentMethods: {
      list: [],
      error: null,
      loading: false,
    },
    subscriptions: {
      list: [],
      error: null,
      loading: false,
    },
    subscriptionsDetails: {
      list: [],
      error: null,
      loading: false,
    },
    changePaymentWithRerunSubscription: {
      error: null,
      loading: false,
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPaymentMethods.pending, (state) => {
        state.paymentMethods.list = [];
        state.paymentMethods.error = null;
        state.paymentMethods.loading = true;
      })
      .addCase(getPaymentMethods.fulfilled, (state, action) => {
        state.paymentMethods.list = action.payload;
        state.paymentMethods.loading = false;
      })
      .addCase(getPaymentMethods.rejected, (state, action) => {
        state.paymentMethods.error = action.payload;
        state.paymentMethods.loading = false;
      });

    builder
      .addCase(getSubscriptions.pending, (state) => {
        state.subscriptions.list = [];
        state.subscriptions.error = null;
        state.subscriptions.loading = true;
      })
      .addCase(getSubscriptions.fulfilled, (state, action) => {
        state.subscriptions.list = action.payload;
        state.subscriptions.loading = false;
      })
      .addCase(getSubscriptions.rejected, (state, action) => {
        state.subscriptions.error = action.payload;
        state.subscriptions.loading = false;
      });

    builder
      .addCase(getSubscriptionsDetails.pending, (state) => {
        state.subscriptionsDetails.list = [];
        state.subscriptionsDetails.error = null;
        state.subscriptionsDetails.loading = true;
      })
      .addCase(getSubscriptionsDetails.fulfilled, (state, action) => {
        state.subscriptionsDetails.list = action.payload;
        state.subscriptionsDetails.loading = false;
      })
      .addCase(getSubscriptionsDetails.rejected, (state, action) => {
        state.subscriptionsDetails.error = action.payload;
        state.subscriptionsDetails.loading = false;
      });

    builder
      .addCase(createPaymentMethod.pending, (state) => {
        state.paymentMethods.error = null;
        state.paymentMethods.loading = true;
      })
      .addCase(createPaymentMethod.fulfilled, (state) => {
        state.paymentMethods.error = null;
        state.paymentMethods.loading = false;
      })
      .addCase(createPaymentMethod.rejected, (state, action) => {
        state.paymentMethods.error = action.payload;
        state.paymentMethods.loading = false;
      });

    builder
      .addCase(cancelSubscription.pending, (state) => {
        state.subscriptions.error = null;
        state.subscriptions.loading = true;
      })
      .addCase(cancelSubscription.fulfilled, (state) => {
        state.subscriptions.error = null;
        state.subscriptions.loading = false;
      })
      .addCase(cancelSubscription.rejected, (state, action) => {
        state.subscriptions.error = action.payload;
        state.subscriptions.loading = false;
      });

    builder
      .addCase(createPaymentMethodWithRerunSubscription.pending, (state) => {
        state.changePaymentWithRerunSubscription.error = null;
        state.changePaymentWithRerunSubscription.loading = true;
      })
      .addCase(createPaymentMethodWithRerunSubscription.fulfilled, (state) => {
        state.changePaymentWithRerunSubscription.error = null;
        state.changePaymentWithRerunSubscription.loading = false;
      })
      .addCase(createPaymentMethodWithRerunSubscription.rejected, (state, action) => {
        state.changePaymentWithRerunSubscription.error = action.payload;
        state.changePaymentWithRerunSubscription.loading = false;
      });
  },
});

export default billingSlice.reducer;
