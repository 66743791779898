import { config } from "_config";
import { getFileExtension } from "_helpers/parsers";

export function validatePassword(password) {
  const checkLength = new RegExp(/^.{8,}$/);
  const hasDigit = new RegExp(/^(?=.*\d).*$/);
  const hasUpperLetter = new RegExp(/^(?=.*[A-Z]).*$/);
  const hasLowerLetter = new RegExp(/^(?=.*[a-z]).*$/);
  const hasSpecialSymbol = new RegExp(/^(?=.*[!@#$%^&*])(?!.*[ ]).*$/);
  const complexCheck = new RegExp(/^[a-zA-Z0-9!@#$%^&*]+$/);

  const c1 = checkLength.test(password);
  const c2 = hasDigit.test(password);
  const c3 = hasUpperLetter.test(password);
  const c4 = hasLowerLetter.test(password);
  const c5 = hasSpecialSymbol.test(password);
  const c6 = complexCheck.test(password);

  return c1 && c2 && c3 && c4 && c5 && c6;
}

export function validateTrim(value) {
  if (value && String(value).trim().length !== value.length) {
    return "Value must not start or end with a space";
  }

  return true;
}

export function fixStringLength(line, length) {
  if (line.length > length) {
    return `${line.slice(0, length)}...`;
  }

  return line;
}

export function openExternalURL(url) {
  window.open(url, "_blank", "noreferrer");
}

export function refreshPage() {
  window.location.reload(true);
}

export function trimString(str) {
  if (str) {
    return String(str).trim();
  }

  return str;
}

export function getFileExtensionsString(arr) {
  if (Array.isArray(arr) && !arr.includes("*")) {
    return arr.map((ext) => "." + ext).join(", ");
  }

  return "";
}

export function checkIfFileAllowed(ext, allowedExt) {
  if (!Array.isArray(allowedExt) || allowedExt === undefined || allowedExt.includes("*") || allowedExt.length === 0) {
    return true;
  }

  return allowedExt.includes(ext);
}

export function sanitizeFileName(fileName) {
  if (fileName) {
    const extension = getFileExtension(fileName, true);
    const name = fileName.slice(0, fileName.length - extension.length);
    const result = name.replace(/[^a-zA-Z0-9._-]/g, "") + extension;

    return result;
  }

  return;
}

export function validateUrl(url) {
  try {
    const validUrl = new URL(url);

    return validUrl ? true : false;
  } catch (_) {
    return false;
  }
}

export function checkIfTestCard(cardNumber) {
  const testNumbers = ["0000000000000000"];

  if (["dev", "stage"].includes(config.envName)) {
    testNumbers.push("7000000000000001");
  }

  return testNumbers.includes(cardNumber);
}

export function handleProductName(productName) {
  const result = productName?.replace("V2", "").replace("V3", "").trim();

  return result;
}

export function handleCustomerStatus(status) {
  switch (status) {
    case "Claimed":
    case "Automation Failed":
    case "AutomationFailed":
    case "ReadyToFile":
    case "Ready to file":
      return "Processing";

    default:
      return status;
  }
}

export function valueToNull(value) {
  const arrayOfValues = ["-", "–", "N/A", null, undefined];

  if (arrayOfValues.includes(value)) return null;

  return value;
}

export function replaceEmptyStringsWithNull(obj) {
  if (obj && typeof obj === "object") {
    const newObj = Array.isArray(obj) ? [] : {};

    Object.keys(obj).forEach((key) => {
      newObj[key] = replaceEmptyStringsWithNull(obj[key]);
    });

    return newObj && Object.keys(newObj).length > 0
      ? Object.entries(newObj).reduce((acc, [key, value]) => {
          acc[key] = value === "" ? null : value;
          return acc;
        }, {})
      : newObj;
  }

  return obj === "" ? null : obj;
}
