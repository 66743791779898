import { AMENDMENT_PRODUCTS, PRODUCT_CODES } from "_constants/products";

export const EIN_title_array = {
  ceo: "CEO",
  executor: "Executor",
  owner: "Owner",
  "managing member": "Managing Member",
  "managing member/owner": "Managing Member/Owner",
  president: "President",
  // other: "Other",
};

export const amendments_options_array = [
  {
    label: "Change Business Address",
    value: AMENDMENT_PRODUCTS.incStatesAmendmentBusinessAddress,
  },
  {
    label: "Change Manager",
    value: AMENDMENT_PRODUCTS.incStatesAmendmentManager,
  },
  {
    label: "Change Office",
    value: AMENDMENT_PRODUCTS.incStatesAmendmentOffice,
    deprecated: true,
  },
  {
    label: "Change Registered Agent",
    value: AMENDMENT_PRODUCTS.incStatesAmendmentRegisteredAgent,
  },
  {
    label: "Change Business Name",
    value: AMENDMENT_PRODUCTS.incStatesAmendmentBusinessName,
  },
  {
    label: "Change Business Name (Foreign Qualification)",
    value: AMENDMENT_PRODUCTS.incStatesAmendmentForeignBusinessName,
    deprecated: true,
  },
  {
    label: "Other",
    value: AMENDMENT_PRODUCTS.incStatesAmendmentAllOtherAmendments,
  },
  {
    label: "Trademark - Minor Amendment",
    value: AMENDMENT_PRODUCTS.incStatesAmendmentTrademarkMinor,
    requiredProduct: PRODUCT_CODES.incStatesRegisterTrademark,
  },
  {
    label: "Trademark - Intensive Amendment",
    value: AMENDMENT_PRODUCTS.incStatesAmendmentTrademarkIntensive,
    requiredProduct: PRODUCT_CODES.incStatesRegisterTrademark,
  },
];

export const STATE_MAP = {
  AL: "Alabama",
  AK: "Alaska",
  AS: "American Samoa",
  AZ: "Arizona",
  AR: "Arkansas",
  AA: "Armed Forces Americas",
  AE: "Armed Forces Europe, Canada, Africa and Middle East",
  AP: "Armed Forces Pacific",
  CA: "California",
  CO: "Colorado",
  CT: "Connecticut",
  DE: "Delaware",
  DC: "District Of Columbia",
  FM: "Federated States of Micronesia",
  FL: "Florida",
  GA: "Georgia",
  GU: "Guam",
  HI: "Hawaii",
  ID: "Idaho",
  IL: "Illinois",
  IN: "Indiana",
  IA: "Iowa",
  KS: "Kansas",
  KY: "Kentucky",
  LA: "Louisiana",
  ME: "Maine",
  MD: "Maryland",
  MA: "Massachusetts",
  MI: "Michigan",
  MN: "Minnesota",
  MS: "Mississippi",
  MO: "Missouri",
  MT: "Montana",
  NE: "Nebraska",
  NV: "Nevada",
  NH: "New Hampshire",
  NJ: "New Jersey",
  NM: "New Mexico",
  NY: "New York",
  NC: "North Carolina",
  ND: "North Dakota",
  MP: "Northern Mariana Islands",
  OH: "Ohio",
  OK: "Oklahoma",
  OR: "Oregon",
  PA: "Pennsylvania",
  PR: "Puerto Rico",
  MH: "Republic of Marshall Islands",
  RI: "Rhode Island",
  SC: "South Carolina",
  SD: "South Dakota",
  TN: "Tennessee",
  TX: "Texas",
  UT: "Utah",
  VT: "Vermont",
  VI: "Virgin Islands",
  VA: "Virginia",
  WA: "Washington",
  WV: "West Virginia",
  WI: "Wisconsin",
  WY: "Wyoming",
};

export const STATE_OPTIONS = Object.entries(STATE_MAP).map(([key, value]) => {
  return {
    label: value,
    value: key,
  };
});

export const MONTH_OPTIONS = [
  { value: "1", label: "January" },
  { value: "2", label: "February" },
  { value: "3", label: "March" },
  { value: "4", label: "April" },
  { value: "5", label: "May" },
  { value: "6", label: "June" },
  { value: "7", label: "July" },
  { value: "8", label: "August" },
  { value: "9", label: "September" },
  { value: "10", label: "October" },
  { value: "11", label: "November" },
  { value: "12", label: "December" },
];

export const MONTH_DAYS = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

export const DAY_OPTIONS = Array.from({ length: 31 }, (_, i) => {
  return { value: `${i + 1}`, label: `${i + 1}` };
});

export const UPDATE_REQUIRED_CODES = {
  refresh: "REFRESH",
  incStatesBusinessLicenses: "UPSELL/BusinessLicenses",
  incStatesRegisterTrademark: "UPSELL/RegisterTrademark",
};

export const SUFFIX_OPTIONS = [
  { value: "DDS", label: "DDS" },
  { value: "MD", label: "MD" },
  { value: "PhD", label: "PhD" },
  { value: "JR", label: "JR" },
  { value: "SR", label: "SR" },
  { value: "I", label: "I" },
  { value: "II", label: "II" },
  { value: "III", label: "III" },
  { value: "IV", label: "IV" },
  { value: "V", label: "V" },
  { value: "VI", label: "VI" },
];

export const EIN_TITLE_OPTIONS = [
  { value: "ceo", label: "CEO" },
  { value: "executor", label: "Executor" },
  { value: "owner", label: "Owner" },
  { value: "managing member", label: "Managing Member" },
  { value: "managing member/owner", label: "Managing Member/Owner" },
  { value: "president", label: "President" },
  { value: "other", label: "Other" },
];

export const ACTIVITY_REASON_FOR_APPLYING_OPTIONS = [
  { value: "started_business", label: "Started New Business" },
  { value: "hired_employees", label: "Hired Employees" },
  { value: "banking_purposes", label: "Banking Purposes" },
  { value: "changed_organization", label: "Changed Type of Organization" },
  { value: "purchased_business", label: "Purchased Business" },
];

export const REJECTION_REASON_ARRAY = {
  altName: "ALTERNATIVE_NAME",
};

export const REFILE_ABILITY_ARRAY = {
  freeReFile: "FREE_RE_FILE",
  freeReFileNotAvailable: "FREE_RE_FILE_NOT_AVAILABLE",
};

export const CUSTOMER_ACTIONS = {
  abandonProduct: "ABANDON_PRODUCT",
  declineBoi: "DECLINE_BOI",
  alreadyHasDissolution: "ALREADY_HAS_DISSOLUTION",
};

export const ERROR_ACTIONS_OBJ = Object.freeze({
  NameConflict: {
    productCodes: [PRODUCT_CODES.incStatesLLCIncorporation, PRODUCT_CODES.incStatesLLCIncorporationV2],
    action: {
      actionRequired: "Name Conflict – We Need A Different Entity Name",
      customerActionDescription:
        "We have received word that there is a conflict with the entity name you have chosen, which is currently preventing your submission. This could mean that a very similar name was used by a different individual.\nTo get this resolved, please provide us with 2 additional name choices.",
    },
  },
  MismatchError: {
    productCodes: [PRODUCT_CODES.incStatesEIN],
    action: {
      actionRequired: "There Has Been a Mismatch, Please Confirm Your Name",
      customerActionDescription:
        "We have encountered an error while validating the information provided in your EIN submission. Please verify that the information below is correct, this MUST be your official name.",
    },
  },
  CardDeclined: {
    productCodes: [],
    action: {
      actionRequired: "Your Payment Method on File Has Declined",
      customerActionDescription:
        "We have received a declined message for your payment method on file. To proceed with your order please update the information on file and verify with your bank that the account is in good standing.",
    },
  },
  WVInformationNeeded: {
    productCodes: [PRODUCT_CODES.incStatesLLCIncorporation, PRODUCT_CODES.incStatesLLCIncorporationV2],
    action: {
      actionRequired: "West Virginia Information Needed",
      customerActionDescription:
        "The state of West Virginia requires additional information to complete the LLC formation filing process. To ensure a smooth and timely filing, we need your assistance in providing the necessary details at your earliest convenience.",
    },
  },
});

export const MONTHLY_VOLUME_OPTIONS_ARRAY = [
  { label: "$0 - $5,000", value: "MV_0_5000" },
  { label: "$5,001 - $10,000", value: "MV_5001_10000" },
  { label: "$10,001 - $25,000", value: "MV_10001_25000" },
  { label: "$25,001 - $50,000", value: "MV_25001_50000" },
  { label: "$50,001 - $100,000", value: "MV_50001_100000" },
  { label: "$100,001 - $250,000", value: "MV_100001_250000" },
  { label: "$250,000+", value: "MV_250001_MAX" },
];
